import React from "react"
import HeaderImage from '../bessel.png';

function Header(){

    return(
        <div> 
            <img className="HeaderImage" src={HeaderImage} alt="headerImage"/>
            <div>Human Resources Group</div>
            <div>Detroit, MI</div>
        </div>
    )
}

export default Header