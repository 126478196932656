import React from "react"
import MixData from "../MixData"

function Player(props){
    const artist = props.mix
    const trackID = MixData[artist];

    return(
        <div className="section"> 
        {
            props.mix !== "" ?
            <div className="Player">
                <iframe title="Soundcloud" width="100%" height="126" scrolling="no" frameborder="no" allow="autoplay" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + trackID + "&color=%23000000&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"}></iframe>
            </div> :
            null
        }
 
        </div>
    )
}

export default Player


