import React from "react"

class HrMixes extends React.Component{

    selectMix(artist) {
        this.props.selectMix(artist);
    }

    render() {
        return(
            <div className="section"> 
                <div className="BigText">HR Mixes</div>
                <div className="Mix" onClick={() => this.selectMix("ruckus")}>HR MIX 012 - RUCKUS</div>
                <div className="Mix" onClick={() => this.selectMix("ben_christensen")}>HR MIX 011 - Ben Christensen</div> 
                <div className="Mix" onClick={() => this.selectMix("north_lake")}>HR MIX 010 - North Lake</div>
                <div className="Mix" onClick={() => this.selectMix("ice_cold_chrissy")}>HR MIX 009 - ICE COLD CHRISSY & KITKKOLA</div> 
                <div className="Mix" onClick={() => this.selectMix("mgun")}>HR MIX 008 - MGUN</div> 
                <div className="Mix" onClick={() => this.selectMix("robert_perry")}>HR MIX 007 - Robert Perry</div>
                <div className="Mix" onClick={() => this.selectMix("radio_brovold")}>HR MIX 006 - Radio Brovold</div>
                <div className="Mix" onClick={() => this.selectMix("at_nu")}>HR MIX 005 - AT/NU</div>
                <div className="Mix" onClick={() => this.selectMix("blksystem")}>HR MIX 004 - BLKSYSTEM</div>
                <div className="Mix" onClick={() => this.selectMix("community_corporation")}>HR MIX 003 - Community Corporation</div>
                <div className="Mix" onClick={() => this.selectMix("conor_laurin")}>HR MIX 002 - Conor Laurin</div>
                <div className="Mix" onClick={() => this.selectMix("gallons")}>HR MIX 001 - GALLONS</div>
            </div>
        )
    }
}

export default HrMixes