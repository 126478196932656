import React from "react"

function Releases(){

    return(
        <div className="section"> 
            <div className="BigText">Music</div>
            <a href="https://humanresourcesgroup.bandcamp.com/album/full-frontal-chemtrails-web-single-hr001" target="_blank" rel="noopener noreferrer">HR001 - Full Frontal - Chemtrails (single)</a>
        </div>
    )
}

export default Releases