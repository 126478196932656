const MixData = {
    conor_laurin: "103008115",
    gallons: "90467379",
    blksystem: "129834726",
    at_nu: "139061622",
    radio_brovold: "146502258",
    community_corporation: "111413687",
    robert_perry: "150339047",
    mgun: "177505217",
    ice_cold_chrissy: "187053700",
    north_lake: "197482460",
    ben_christensen: "219982675",
    ruckus: "225243400"
}

export default MixData;
