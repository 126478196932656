import React from "react"

function Releases(){

    return(
        <div className="section"> 
            <div className="BigText">Links</div>
            <a target="_blank" rel="noopener noreferrer" href="https://humanresourcesgroup.bandcamp.com">Bandcamp</a> <br />
            <a target="_blank" rel="noopener noreferrer" href="https://soundcloud.com/humanresourcesgroup">Soundcloud</a> <br />
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hrgrprecords">Twitter</a> <br />
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/humanresourcesgroup/">Instagram</a> <br />
        </div>
    )
}

export default Releases