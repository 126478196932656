import React from 'react';
import './App.css';
import HrMixes from './components/HrMixesComponent';
import Releases from './components/ReleasesComponent';
import Links from './components/LinksComponent';
import Header from './components/HeaderComponent';
import Player from './components/PlayerComponent';

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedMix: "",
    }
  }

  selectMix = (mix) => {
    this.setState({
      selectedMix: mix
    })
  }

  render(){
    return (
      <div className="App">
        <header className="App-header">
          <Header />
          <HrMixes selectMix={this.selectMix} />
          <Releases />
          <Links />
          <Player mix={this.state.selectedMix}/>
        </header>
      </div>
    )
  }
}

export default App;
